@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&subset=cyrillic-ext');

:root {

}

body {
  font-family: 'Roboto', sans-serif;
  /* width: 1110px; */
  /* margin-left: 100%; */
  /* margin-right: 50%; */
  /* text-align: center; */
}

a {
  color: #727e86;
}

a, a:hover, a:visited, a:active {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

section {
  margin-top: 2em;
  margin-bottom: 2em;
}

/* Common Styles */
.invisible {
  opacity: 0;
}

/* Header */
.header-controls-pics {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
  margin-left: auto;
}
.header-controls-pic {
  height: 32px;
  width: 32px;
  margin: 0 10px;
  background-image: url(../img/header-controls-sprite.png);
  background-repeat: no-repeat;
  cursor: pointer;
}
.header-controls-search {
  position: relative;
  z-index: 1;
  background-position: -64px 0;
}
.header-controls-profile {
  position: relative;
  background-position: -32px 0;
}
.header-controls-cart {
  position: relative;
  background-position: 0 0;
}
.header-controls-cart-full {
  position: absolute;
  right: -10px;
  top: -5px;
  display: block;
  height: 21px;
  width: 21px;
  font-size: 11px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 50%;
  background: linear-gradient(90deg, #ff65a5 1.11%, #ff8076 100%);
  box-shadow: 0px 2px 8px rgba(255, 101, 165, 0.6), inset 0px 1px 1px #ff8076;
}
.header-controls-search-form {
  position: absolute;
  right: 65px;
  top: calc(50% - 19px);
  display: block;
  width: 233px;
  height: 38px;
  padding: 0 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px #D4E4E6;
  border-radius: 50px;
  text-align: right;
}
.header-controls-search-form .form-control {
  width: 100%;
  height: 100%;
  color: #333333;
  border: none;
  outline: none;
  box-shadow: none;
}
/* .nav-item{
  display: inline;
}
.navbar{
  display: inline;
}
.navbar-brand{
  display: inline!important;
}
.collapase{
  display: inline!important;
}
.row-line{
  display: inline!important;
}
.col{
  display: inline!important;
} */
/* Banner */
.row-line{
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 0px!important;
  margin-right: 0px!important;
}
.card-img{
  width: 100%!important;

}

.card-body-padding{
  padding: 0px!important;
  /* margin-bottom: -500px; */
}
.card-line{
  border: 0px solid rgba(0,0,0,.125)!important;
  cursor: pointer;
  vertical-align: top;
}
.card-border{
  border: 1px solid rgba(0,0,0,.125)!important;
  cursor: pointer;
  vertical-align: top;
  height: 320px;
}
.card-inline{
  display: inline-block!important;
  /* max-width:100px!important; */
}
.card-catalog{
  min-height: 320px;
  max-height: 370px!important;
  background-color: #63b2d6;
  background-repeat: no-repeat;
  background-image: url(../img/color-icon1.png);
  background-position: center;
  /* vertical-align: center; */
  cursor: pointer;
}
.card-catalog2{
  min-height: 320px;
  max-height: 370px!important;
  background-color: #a0b046;
  background-repeat: no-repeat;
  background-image: url(../img/color-icon2.png);
  background-position: center;
  /* vertical-align: center; */
  cursor: pointer;
}
.card-catalog3{
  min-height: 320px;
  max-height: 370px!important;
  background-color: #f78145;
  background-repeat: no-repeat;
  background-image: url(../img/color-icon3.png);
  background-position: center;
  /* vertical-align: center; */
  cursor: pointer;
}
.banner {
  position: relative;
  display: inline-flex;
}
.banner-pad {
padding-left: 0px!important;
}
.text-bot {
margin-top: 150px;
}

.banner-wrap {
  padding-top: 20px;
  /* display: inline-flex; */
  /* position: absolute;
  z-index: 5; */
  color: #727e86;
}
.banner-header {
  /* position: absolute;
  z-index: 5; */
  /* display: inline-block; */


  /* right: 0;
  top: 0px;
  height: 340px;
  width: 400px; */
  font-weight: bold;
  font-size: 16px;
  /* line-height: 98px;
  text-align: center;
  color: #FFFFFF;
  background: linear-gradient(270deg, rgba(161, 179, 191, 0.8) 72.59%, rgba(161, 179, 191, 0) 98.05%);
  padding-right: 10px; */
}
.banner-header-text {
  display: block;
  /* position: inherit; */
  /* z-index: 7; */
  /* right: 0;
  top: 0px; */
}

/* Preloader */
.preloader {
  margin: 10px auto;  
  position: relative;
  width: 30px;
  height: 30px;
}
.preloader span {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}
.preloader :first-child {
  background: #19A68C;
  animation-delay: -1.5s;
}
.preloader :nth-child(2) {
  background: #ff748b;
  animation-delay: -1s;
}
.preloader :nth-child(3) {
  background: #ffb676;
  animation-delay: -0.5s;
}
.preloader :last-child {
  background: #727e86;
}
@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(160%);
  }
  50% {
    transform: translate(160%, 160%);
  }
  75% {
    transform: translate(0, 160%);
  }
}

/* Top Sales */
.top-sales {
  margin-top: 50px;
  min-height: 300px;
}

/* Catalog */
.catalog {
  min-height: 600px;
}

.catalog-categories {
  font-size: 1.3rem;
  margin-bottom: 2em;
}
.catalog-item-card {
  margin-bottom: 1em;
}

/* Footer */
.footer {
  padding: 0 25px;
}

.footer-pay {
  display: flex;
  justify-content: space-between;
  margin: 12px auto 0;
}
.footer-pay-systems {
  width: 46px;
  height: 28px;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-image: url(../img/footer-sprite.png);
}
.footer-pay-systems:not(:first-child) {
  margin-left: 5px;
}
.footer-pay-systems-paypal {
  background-position: -2px -6px;
}
.footer-pay-systems-master-card {
  background-position: -54px -8px;
}
.footer-pay-systems-visa {
  background-position: -104px -8px;
}
.footer-pay-systems-yandex {
  background-position: -154px -8px;
}
.footer-pay-systems-webmoney {
  background-position: -2px -46px;
}
.footer-pay-systems-qiwi {
  background-position: -52px -46px;
}

.footer-social-links {
  margin-top: 10px;
  text-align: center;
}
.footer-social-link {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin: 0 5px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-image: url(../img/footer-sprite.png);
}
.footer-social-link-twitter {
  background-position: -107px -42px;
}
.footer-social-link-vk {
  background-position: -157px -42px;
}
.footer-social-link:hover {
  cursor: pointer;
}

.footer-copyright {
  font-size: 0.8em;
}

.footer-contacts-phone {
  display: block;
}

.footer-contacts-email {
  display: block;
}
.nav-link-footer {
  padding-top: 5px!important;
  padding-left: 15px;
}
.nav-link-item {
  background: url(../img/li.png) no-repeat left 14px;
}
.a-link{
  color: #727e86;
}

.a-link:hover{
  text-decoration: none;
}
/* Bootstrap */
.nav-link.active {
  color: #ff0000;
}

h2, .h2 {
  margin-bottom: 1.5em;
  margin-top: 2em;
}

.btn-outline-primary {

  color: #727e86;
}

a, a:hover, a:visited, a:active {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

section {
  margin-top: 2em;
  margin-bottom: 2em;
}

/* Common Styles */
.invisible {
  opacity: 0;
}

/* Header */
.header-controls-pics {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
  margin-left: auto;
}
.header-controls-pic {
  height: 32px;
  width: 32px;
  margin: 0 10px;
  background-image: url(../img/header-controls-sprite.png);
  background-repeat: no-repeat;
  cursor: pointer;
}
.header-controls-search {
  position: relative;
  z-index: 1;
  background-position: -64px 0;
}
.header-controls-profile {
  position: relative;
  background-position: -32px 0;
}
.header-controls-cart {
  position: relative;
  background-position: 0 0;
}
.header-controls-cart-full {
  position: absolute;
  right: -10px;
  top: -5px;
  display: block;
  height: 21px;
  width: 21px;
  font-size: 11px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 50%;
  background: linear-gradient(90deg, #ff65a5 1.11%, #ff8076 100%);
  box-shadow: 0px 2px 8px rgba(255, 101, 165, 0.6), inset 0px 1px 1px #ff8076;
}
.header-controls-search-form {
  position: absolute;
  right: 65px;
  top: calc(50% - 19px);
  display: block;
  width: 233px;
  height: 38px;
  padding: 0 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px #D4E4E6;
  border-radius: 50px;
  text-align: right;
}
.header-controls-search-form .form-control {
  width: 100%;
  height: 100%;
  color: #333333;
  border: none;
  outline: none;
  box-shadow: none;
}

/* Banner */
/* .banner {
  position: relative;
} */

/* .banner-header {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 42px;
  height: 98px;
  width: 487px;
  font-weight: bold;
  font-size: 48px;
  line-height: 98px;
  text-align: center;
  color: #FFFFFF;
  background: linear-gradient(270deg, rgba(161, 179, 191, 0.8) 72.59%, rgba(161, 179, 191, 0) 98.05%);
} */

/* Preloader */
.preloader {
  margin: 10px auto;  
  position: relative;
  width: 30px;
  height: 30px;
}
.preloader span {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}
.preloader :first-child {
  background: #19A68C;
  animation-delay: -1.5s;
}
.preloader :nth-child(2) {
  background: #ff748b;
  animation-delay: -1s;
}
.preloader :nth-child(3) {
  background: #ffb676;
  animation-delay: -0.5s;
}
.preloader :last-child {
  background: #727e86;
}
@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(160%);
  }
  50% {
    transform: translate(160%, 160%);
  }
  75% {
    transform: translate(0, 160%);
  }
}

/* Top Sales */
.top-sales {
  min-height: 300px;
}

/* Catalog */
.catalog {
  min-height: 600px;
}

.catalog-categories {
  font-size: 1.3rem;
  margin-bottom: 2em;
}

.catalog-item-card {
  margin-bottom: 1em;
}

.catalog-item-size {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}

.catalog-item-size.selected {
  background: #727e86;
  border-radius: 15px;
  color: #fff;
}

.catalog-search-form {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px #D4E4E6;
  border-radius: 50px;
  margin-bottom: 2em;
}
.catalog-search-form .form-control {
  width: 100%;
  height: 100%;
  color: #333333;
  border: none;
  outline: none;
  box-shadow: none;
}

/* Footer */
.footer {
  padding: 0 25px;
}

.footer-pay {
  display: flex;
  justify-content: space-between;
  margin: 12px auto 0;
}
.footer-pay-systems {
  width: 46px;
  height: 28px;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-image: url(../img/footer-sprite.png);
}
.footer-pay-systems:not(:first-child) {
  margin-left: 5px;
}
.footer-pay-systems-paypal {
  background-position: -2px -6px;
}
.footer-pay-systems-master-card {
  background-position: -54px -8px;
}
.footer-pay-systems-visa {
  background-position: -104px -8px;
}
.footer-pay-systems-yandex {
  background-position: -154px -8px;
}
.footer-pay-systems-webmoney {
  background-position: -2px -46px;
}
.footer-pay-systems-qiwi {
  background-position: -52px -46px;
}

.footer-social-links {
  margin-top: 10px;
}
.footer-social-link {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin: 0 5px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-image: url(../img/footer-sprite.png);
}
.footer-social-link-twitter {
  background-position: -107px -42px;
}
.footer-social-link-vk {
  background-position: -157px -42px;
}
.footer-social-link:hover {
  cursor: pointer;
}

.footer-copyright {
  font-size: 0.8em;
}

.footer-contacts-phone {
  display: block;
}

.footer-contacts-email {
  display: block;
}

/* Bootstrap */
.nav-link.active {
  /* color: #ff0000; */
  /* margin-top: 5px; */
}

h2, .h2 {
  margin-bottom: 1.5em;
  margin-top: 2em;
}

.btn-outline-primary {
  color: #727e86;
  border-color: #727e86;
}

.btn-outline-primary:hover {
  background-color: #727e86;
  border-color: #727e86;
}

.dropdown {
  display: inline;
}
.dropdown-content {
  display: none;
  position: absolute;
  left: 0px!important;
  top: 40px!important;
  min-width: 120px;
  z-index: 102;
  background: rgba(41, 41, 41, 0.8)!important;
}

/* Links inside the dropdown */

.dropdown-content a {
  color: rgb(255, 255, 255)!important;
  padding: 12px 16px;
  display: block;
}

/* Change color of dropdown links on hover */

.dropdown-content a:hover {
  background: rgba(41, 41, 41, 0.7)!important;
  color: #2a83d6!important;
}

/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.img-size {
  height: 100%;
  width: 100%;
}

.inp-sent {
  width: 250px;
  margin-left: 0px;
  margin-right: 3px;
  border-radius: 3px;
  border: 0px;
  text-indent: 10px;
  margin-top: 10px;
}
.inp-date-border {
  border: 2px solid rgb(0, 0, 0);
}
.fb-cont {
  display: flex;
  margin-top: 25px;
}
.fb-form {
  margin-top: 5px;
  display: block;
}
.calc-text {
  font-size: 15px;
  color: #000000;
  margin-top: 5px;
  margin-left: 3px;
  margin-bottom: 10px;
}
.but-sent {
  width: 248px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-top: 15px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 100;
  color: #535353;
  font-style: normal;
  display: block;
}
.form-left {
  text-align: left;
  margin-left: 40px;
}






